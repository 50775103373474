body, html{
    height: 100%;
    margin: 0;
}

.hello{
    color: #04619f;
}

.subtitle{
    margin-right: auto;
    font-size: 33px;
    letter-spacing: 3px;
}

.text{
    margin-left: 100px;
    margin-right: auto;
}

.banner{
    height: 750px;
    width: 100%; 
}


.hello {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s
  }
  @-webkit-keyframes animatebottom {
    from { bottom:-50px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  @keyframes animatebottom { 
    from{ bottom:-50px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }

@media screen and (max-width: 960px){
    .text{
        text-align: left;
        margin-left: 20px;
        font-size: small;
    }  

}

.title {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s
  }
  @-webkit-keyframes animatebottom {
    from { bottom:-50px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  @keyframes animatebottom { 
    from{ bottom:-50px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }


