@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
*{
  font-family: Montserrat;
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

.App{
  overflow-x: hidden;
}







.navigation{
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    /*border-bottom: solid #04619f 2px;*/
    background-color: #04619f;
}

.navigation a {
    color: #fff;
    padding: 0.5rem 1rem;

}

.navigation a.middle{
    position: relative;
}

.navigation a.middle:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.navigation a.middle:hover:before{
    visibility: visible;
    transform: scaleX(1);
}


/*.navigation a:hover{
    border-radius: 4px;
    background-color: rgb(201, 196, 196) ;
    transition: all 0.2s ease-out;
}*/

.nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.fa-bars{
    color: #fff;
    font-size: 20px;
    margin-right:10px;
}

.fa-bars:hover{
    cursor: pointer;
}

.fa-times:hover{
    cursor: pointer;
}

.fa-times{
    color: #fff;
    font-size: 20px;
    margin-right:10px;

}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .navigation{
        position: relative;
        opacity: 1;
        z-index: 1;

    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        height: 364px;
        width: 120%;
        position: absolute;
        top: -1000%;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: #04619f;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 0;
        margin-top: 70px;
        overflow: visible;
    }

    .navigation a {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }


    .navigation a.middle:after:hover{
        cursor: pointer;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
    }

    .name{
        display: none;
    }
    
    .navigation a.middle:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;        
        background-color: #04619f;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }
    
}


body, html{
    height: 100%;
    margin: 0;
}

.hello{
    color: #04619f;
}

.subtitle{
    margin-right: auto;
    font-size: 33px;
    letter-spacing: 3px;
}

.text{
    margin-left: 100px;
    margin-right: auto;
}

.banner{
    height: 750px;
    width: 100%; 
}


.hello {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s
  }
  @keyframes animatebottom { 
    from{ bottom:-50px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }

@media screen and (max-width: 960px){
    .text{
        text-align: left;
        margin-left: 20px;
        font-size: small;
    }  

}

.title {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s
  }
  @keyframes animatebottom { 
    from{ bottom:-50px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }



.content{
    padding: 50px 100px;
}

.about{
    font-size: 20px;
    letter-spacing: 1px;
    text-align: left;
    line-height: 1.7;
}

.fa-check-circle{
    color: #04619f;
}

.list{
    font-size: 20px;
    line-height: 1.3;
    list-style: none;
}

li{
    margin-bottom: 10px;
    display: inline;
    float: left;
    margin-right: 15px;
}

footer{
    margin-top: 50px;
}

.list{
    font-size: 20px;
    line-height: 1.3;
    list-style: none;
}

@media screen and (max-width: 960px){
    .about{
        margin-right: 0;
        font-size: 15px;
        padding: 0px 0px 20px;
    }

    .content{
        padding: 20px;
    }

    .list{
        font-size: small;
    }

    footer{
        margin: 70px 0 70px 0;
    }
}

.image {
  height: 300px;
  width: 500px;
  margin: 5px auto auto;
}

.container {
  margin-bottom: 75px;
}

.container span {
  width: 1000px;
  display: block;
  background-color: #04619f;
  height: 2px;
}

.description {
  padding: 30px;
  margin-right: 400px;
}

p {
  font-size: larger;
  line-height: 1.6;
  margin-right: 300px;
}

.info {
  margin-left: 20px;
}

.slider {
  position: relative;
  height: 45vh;
  width: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.fa-arrow-right {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 2rem;
  color: #04619f;
  cursor: pointer;
}

.fa-arrow-left {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 2rem;
  color: #04619f;
  cursor: pointer;
}

.btn {
  background-color: #04619f;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 2px;
  margin-left: 20px;
}

.btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  p {
    margin-right: 0;
  }

  .slider {
    height: 50%;
    width: 50%;
    margin: 5px auto auto;
  }

  .description {
    font-size: medium;
    padding: 5px;
    margin: 20px;
  }

  .container span {
    width: 100%;
    display: block;
    background-color: #04619f;
    height: 2px;
  }
}

.fa-arrow-right{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 2rem;
    color: #04619f;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
}

.fa-arrow-left{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 2rem;
    color: #04619f;
    cursor: pointer;    
    -webkit-user-select: none;    
        -ms-user-select: none;    
            user-select: none;
    z-index: 10;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }

.content{
   /* margin-left: 100px;*/
    margin-top: 50px;
}


@media screen and (max-width: 960px){
    .content {
        text-align: left;
        margin-left: 20px;
    }  
}
body, html{
    height: 100%;
    margin: 0;
}

.resume{
    height: 1000px;
    margin-left: 50px;
}

.embed{
    width: 700px;
     height: 900px;
}

 @media screen and (max-width: 960px){
   

    .embed{
        width: 50%;
        height: 50%;
    }
}

@media screen and (max-width: 613px){
   

    .embed{
        width: 50%;
        height: 35%;
    }
}

@media screen and (max-width: 689px){
   

    .embed{
        width: 50%;
        height: 35%;
    }
}

@media screen and (max-width: 517px){
   
    .embed{
        width: 70%;
        height: 40%;
    }
} 

@media screen and (max-width: 477px){
   
    .embed{
        width: 70%;
        height: 32%;
    }
} 
