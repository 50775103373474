
.navigation{
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    /*border-bottom: solid #04619f 2px;*/
    background-color: #04619f;
}

.navigation a {
    color: #fff;
    padding: 0.5rem 1rem;

}

.navigation a.middle{
    position: relative;
}

.navigation a.middle:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.navigation a.middle:hover:before{
    visibility: visible;
    transform: scaleX(1);
}


/*.navigation a:hover{
    border-radius: 4px;
    background-color: rgb(201, 196, 196) ;
    transition: all 0.2s ease-out;
}*/

.nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.fa-bars{
    color: #fff;
    font-size: 20px;
    margin-right:10px;
}

.fa-bars:hover{
    cursor: pointer;
}

.fa-times:hover{
    cursor: pointer;
}

.fa-times{
    color: #fff;
    font-size: 20px;
    margin-right:10px;

}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .navigation{
        position: relative;
        opacity: 1;
        z-index: 1;

    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        height: 364px;
        width: 120%;
        position: absolute;
        top: -1000%;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: #04619f;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 0;
        margin-top: 70px;
        overflow: visible;
    }

    .navigation a {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }


    .navigation a.middle:after:hover{
        cursor: pointer;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
    }

    .name{
        display: none;
    }
    
    .navigation a.middle:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;        
        background-color: #04619f;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }
    
}

