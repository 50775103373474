.content{
    padding: 50px 100px;
}

.about{
    font-size: 20px;
    letter-spacing: 1px;
    text-align: left;
    line-height: 1.7;
}

.fa-check-circle{
    color: #04619f;
}

.list{
    font-size: 20px;
    line-height: 1.3;
    list-style: none;
}

li{
    margin-bottom: 10px;
    display: inline;
    float: left;
    margin-right: 15px;
}

footer{
    margin-top: 50px;
}

.list{
    font-size: 20px;
    line-height: 1.3;
    list-style: none;
}

@media screen and (max-width: 960px){
    .about{
        margin-right: 0;
        font-size: 15px;
        padding: 0px 0px 20px;
    }

    .content{
        padding: 20px;
    }

    .list{
        font-size: small;
    }

    footer{
        margin: 70px 0 70px 0;
    }
}
