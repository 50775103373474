body, html{
    height: 100%;
    margin: 0;
}

.resume{
    height: 1000px;
    margin-left: 50px;
}

.embed{
    width: 700px;
     height: 900px;
}

 @media screen and (max-width: 960px){
   

    .embed{
        width: 50%;
        height: 50%;
    }
}

@media screen and (max-width: 613px){
   

    .embed{
        width: 50%;
        height: 35%;
    }
}

@media screen and (max-width: 689px){
   

    .embed{
        width: 50%;
        height: 35%;
    }
}

@media screen and (max-width: 517px){
   
    .embed{
        width: 70%;
        height: 40%;
    }
} 

@media screen and (max-width: 477px){
   
    .embed{
        width: 70%;
        height: 32%;
    }
} 