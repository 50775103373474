
.content{
   /* margin-left: 100px;*/
    margin-top: 50px;
}


@media screen and (max-width: 960px){
    .content {
        text-align: left;
        margin-left: 20px;
    }  
}