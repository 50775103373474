a{
  text-decoration: none;
}

.App{
  overflow-x: hidden;
}





