.image {
  height: 300px;
  width: 500px;
  margin: 5px auto auto;
}

.container {
  margin-bottom: 75px;
}

.container span {
  width: 1000px;
  display: block;
  background-color: #04619f;
  height: 2px;
}

.description {
  padding: 30px;
  margin-right: 400px;
}

p {
  font-size: larger;
  line-height: 1.6;
  margin-right: 300px;
}

.info {
  margin-left: 20px;
}

.slider {
  position: relative;
  height: 45vh;
  width: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.fa-arrow-right {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 2rem;
  color: #04619f;
  cursor: pointer;
}

.fa-arrow-left {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 2rem;
  color: #04619f;
  cursor: pointer;
}

.btn {
  background-color: #04619f;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 2px;
  margin-left: 20px;
}

.btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  p {
    margin-right: 0;
  }

  .slider {
    height: 50%;
    width: 50%;
    margin: 5px auto auto;
  }

  .description {
    font-size: medium;
    padding: 5px;
    margin: 20px;
  }

  .container span {
    width: 100%;
    display: block;
    background-color: #04619f;
    height: 2px;
  }
}
