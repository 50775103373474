@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

*{
  font-family: Montserrat;
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
