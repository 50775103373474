.fa-arrow-right{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 2rem;
    color: #04619f;
    cursor: pointer;
    user-select: none;
    z-index: 10;
}

.fa-arrow-left{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 2rem;
    color: #04619f;
    cursor: pointer;    
    user-select: none;
    z-index: 10;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }